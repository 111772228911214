<template>
  <div class="animated fadeIn">
    <div class="content-page">
      <h1>Инструкция использования личного кабинета</h1>
      <div class="content-page__navigation">
        <h6>Содержание</h6>
        <ol class="content-page__navigation-list">
          <li class="content-page__navigation-item">
            <a
              href="#list-item-1"
              class="content-page__navigation-link"
              @click.prevent="scrollToItem('list-item-1')"
            >Раздел «Аукционы»</a>
          </li>
          <li class="content-page__navigation-item">
            <a
              href="#list-item-2"
              class="content-page__navigation-link"
              @click.prevent="scrollToItem('list-item-2')"
            >Раздел «Тендеры»</a>
          </li>
          <li class="content-page__navigation-item">
            <a
              href="#list-item-3"
              class="content-page__navigation-link"
              @click.prevent="scrollToItem('list-item-3')"
            >Верификация водителей</a>
          </li>
        </ol>
      </div>
      <ol>
        <li ref="list-item-1">
          Раздел «Аукционы»
          <ol>
            <li>
              <b>Вход на ЭТП</b> осуществляется по вашему логину и паролю на windows
            </li>
            <li>
              <b>Аукцион на рейс</b> – это планируемый рейс с указанными параметрами, по которому выставляется лот для проведения аукционов.
            </li>
            <li>
              <b>Условия проведения Аукциона</b> – перевозчик должен иметь действующий договор перевозки и быть зарегистрированным пользователем на ЭТП.
            </li>
            <li>
              <b>Как отправить рейс на аукцион:</b>
              <ol>
                <li>Посредством ЦУП</li>
                <li>Через ЭТП</li>
              </ol>
            </li>
            <li>
              <b>Как запустить Аукцион:</b>
              <ol>
                <li>Через ЭТП</li>
              </ol>
            </li>
            <li>
              <b>Виды Аукциона:</b>
              <ol>
                <li>Черновик – аукцион, ожидающий запуска.</li>
                <li>
                  Активный – Аукцион который уже начался и не закончился на настоящее время
                  <ol>
                    <li>Отложенный = Аукцион, который начнется в установленное время.</li>
                  </ol>
                </li>
                <li>
                  Завершенный – Аукцион, который был завершен:
                  <ol>
                    <li>Выигран перевозчиком</li>
                    <li>
                      Не выигран никем из участников и время аукциона закончилось
                      <ol>
                        <li>Может быть сделано встречное предложение</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>Утвержденный – аукцион, который перевозчик забрал в обработку (занесение данных по водителю, ТС)</li>
                <li>Отмененный – аукцион, который был отменен сотрудником СТЛ (логистом).</li>
              </ol>
            </li>
            <li>
              <b>Краткая схема процесса:</b>
              <ol>
                <li>Сотрудник СТЛ(логист) отправляет на аукцион подтверждённый рейс посредством ЦУП или ЭТП</li>
                <li>Сотрудник СТЛ(логист) запускает аукцион посредством ЭТП</li>
                <li>Перевозчик уведомляется о старте аукциона в ЭТП или на почту</li>
                <li>Перевозчик может участвовать в аукционе на понижение от актуальной ставки на текущее время с шагом, установленным ПЭК. При этом изначальная ставка и шаг ставки может быть либо с НДС либо без (указано в аукционе).</li>
                <li>Если по аукциону доступны встречные предложения, то перевозчик вправе указать свою встречную сумму за рейс (без связи с текущей или изначальной ставкой).</li>
                <li>После завершения Аукциона назначается победитель, с наименьшей ставкой, о чем будет уведомление в ЭТП либо на почту</li>
                <li>Если Аукцион не был разыгран, и перевозчики направили встречное предложение, его необходимо рассмотреть сотрудником СТЛ(логистом) и если сотрудник примет условия перевозчика, то будет назначен победитель аукциона, о чем будет уведомление ЭТП либо на почту.</li>
                <li>После выигранного аукциона, перевозчик должен подтвердить выполнение рейса.</li>
                <li>После подтверждения рейса, рейс переходит в ЭТП на вкладку «Рейсы» и перевозчик должен заполнить все данные по рейсу и отправить данный в информационную систему ПЭК (данные отобразятся в ЦУП и 1 С – водитель, ТС, выигранная ставкка).</li>
                <li>Сотрудник СТЛ(логист) может  менять маршрут рейса только в рамках филиала первой и последней точки маршрута, если между первой и последней точкой добавляются промежуточные точки заезда, то они дополнительно оплачиваются согласно установленным по договоре перевозки либо доп. соглашением между Вами и  ОО «ПЭК»</li>
              </ol>
            </li>
            <li>
              <b>Подробная инструкция:</b>
              <ol>
                <li>
                  <b>Отправка рейса на аукцион – на аукцион можно отправить только рейс (ЗТС нельзя):</b>
                  <ol>
                    <li>
                      Через ЦУП
                      <ol>
                        <li>
                          При создании нового рейса необходимо в поле Заказ ТС выбрать «Аукцион»
                          <img
                            src="/img/pages/customer-manual/1.jpg"
                            alt=""
                          >
                        </li>
                        <li>
                          Любой подтверждённый рейс можно отправить на Аукцион из ЦУП, при этом, если рейс уже был связан с ЗТС, то ЗТС будет отвязан и фрахт очищен.
                          <img
                            src="/img/pages/customer-manual/2.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                    <li>
                      Через ЭТП
                      <ol>
                        <li>
                          Зайти под собой в ЭТП на вкладку Рейсы – выбрать нужный рейс и нажать знак «молотка» - возможно сделать для рейсов не отписанных и по которым не назначен перевозчик.
                          <img
                            src="/img/pages/customer-manual/3.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Запуск Аукциона</b>
                  <ol>
                    <li>
                      После отправки рейса на Аукцион он попадает в меню Аукцион – вкладка «Черновики».
                      <ol>
                        <li>В любой момент Вы можете совсем отменить аукцион, кнопка «красный крест» - аукцион попадет во вкладку «Отмененные»</li>
                      </ol>
                    </li>
                    <li>
                      Перед запуском Аукциона необходимо нажать на значок «редактировать» и заполнить форму аукциона
                      <img
                        src="/img/pages/customer-manual/4.jpg"
                        alt=""
                      >
                      <ol>
                        <li>
                          На форме Аукциона необходимо заполнить данные:
                          <ol>
                            <li>Габариты (категория объема кузова ТС) – по умолчанию выставлена категория  20 т (80-93 м3), можно выбрать только одну категорию</li>
                            <li>Тип кузова – по умолчанию выставлено «тент с усиленной обрешеткой», можно выбрать несколько типов</li>
                            <li>Характер погрузки/выгрузки – по умолчанию: задняя, боковая, верхняя.</li>
                            <li>Выбрать группу участников аукциона. Группы можно создать в разделе «Списки перевозчиков» в меню «Перевозчики»</li>
                            <li>Указать стартовую стоимость</li>
                            <li>Выбрать с НДС или без НДС будет происходить торг</li>
                            <li>Выбрать шаг ставки – т.е.  минимальная величина на которую перевозчик сможет  сделать свое предложение.</li>
                            <li>
                              Если хотите по аукциону получать встречные предложения, то необходимо поставить галку «Возможность подачи встречных предложений»
                              <ol>
                                <li>
                                  При необходимости можете задать время начала подачи встречных предложений, для того чтобы они начали действовать ни сразу после старта аукциона, а например за 1 час до окончания.
                                  <img
                                    src="/img/pages/customer-manual/5.jpg"
                                    alt=""
                                  >
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>Далее либо просто сохраняем данные  - кнопка «Сохранить изменения», либо сразу сохраняем и запускаем  - кнопка «Сохранить и запустить аукцион»</li>
                    <li>
                      После нажатия на кнопки «Сохранить и запустить аукцион, открывается форма  для запуска Аукциона с выбором:
                      <ol>
                        <li>«Запустить сейчас» - время старта аукциона = текущему времени</li>
                        <li>
                          «Запустить, как отложенный» - время старта аукциона = установленному вами времени (больше текущего)
                          <img
                            src="/img/pages/customer-manual/6.jpg"
                            alt=""
                          >
                        </li>
                        <li>
                          Заполняете «Время окончания приемка ставок» и нажимаете кнопку «Запустить Аукцион»
                          <img
                            src="/img/pages/customer-manual/7.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                    <li>
                      Аукцион переходит во вкладку «Активные» и всем участникам аукциона его теперь могут видеть.
                      <img
                        src="/img/pages/customer-manual/8.jpg"
                        alt=""
                      >
                      <ol>
                        <li>
                          В любой момент Вы можете вернуть Аукцион в черновик, кнопка «Вернуть в черновик» либо совсем отменить аукцион, кнопка «красный крест» - аукцион попадет во вкладку «Отмененные»
                          <img
                            src="/img/pages/customer-manual/9.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                    <li>Далее происходит аукцион, статус «Прием ставок». По уведомлению хода проведению Аукциона смотрите в Разделе «Уведомления»</li>
                    <li>
                      Вы можете досрочно назначить победителя- участника, последний раз сделавший минимальную ставку.
                      <img
                        src="/img/pages/customer-manual/10.jpg"
                        alt=""
                      >
                    </li>
                    <li><b><i>Во время проведения Аукциона рейс будет полностью заблокирован для изменений в ЦУП и Пегас, если возникнет потребность редактировать рейс, то по нему необходимо отменить аукцион через ЭТП и блокировка на редактирование будет снята.</i></b></li>
                  </ol>
                </li>
                <li>
                  <b>Завершение Аукциона.</b> После окончания аукциона, аукцион попадает во вкладку «Завершенные», его результат может быть:
                  <ol>
                    <li>
                      <b>Статус «Есть победитель»</b> - Перевозчик может:
                      <ol>
                        <li>
                          «Забрать рейс в обработку» - в таком случае рейс появится у перевозчика во вкладке рейсы и перевозчик сам сможет заполнить данные по водителю и ТС и все данные в том числе и фрахт придет с обменом в 1 С и ЦУП.
                          <img
                            src="/img/pages/customer-manual/11.jpg"
                            alt=""
                          >
                          <ol>
                            <li>Даже, если перевозчик не заполнит данные по водителю и ТС, фрахт по рейсу придет в ЦУП и Пегас.</li>
                            <li><b><i>В выигранном аукционе вы не сможете менять первую и последнюю точку маршрута только в рамках одного филиала</i></b></li>
                            <li>
                              <b><i>Вы сможете добавлять промежуточные точки и добавлять стоимость за их заезд только путем отклонения от выигранной ставки</i></b>
                              <img
                                src="/img/pages/customer-manual/12.jpg"
                                alt=""
                              >
                              <img
                                src="/img/pages/customer-manual/13.jpg"
                                alt=""
                              >
                            </li>
                          </ol>
                        </li>
                        <li>
                          «Отказаться от выигранного рейса» - при отказе перевозчика от выигранного рейса остается в вкладке «Завершенные» со статусом «Нет победителя»
                          <ol>
                            <li>После отказа перевозчика от рейса вы можете: «Перезапустить аукцион», «Вернуть в черновик», «Отменить аукцион»</li>
                          </ol>
                        </li>
                        <li>
                          Даже если определен победитель, вы также можете назначить победителем участника, который сделал встречное предложение (подробнее в разделе статус «нет победителя»)
                          <img
                            src="/img/pages/customer-manual/14.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                    <li>
                      <b>Статус «Нет победителя»</b> - в этом статусе Вам доступны следующие действия: «Перезапустить аукцион», «Вернуть в черновик», «Отменить аукцион» либо назначить победителя если были встречные предложения:
                      <img
                        src="/img/pages/customer-manual/15.jpg"
                        alt=""
                      >
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Утверждение Аукциона.</b> После  подтверждения перевозчиком выигранного им аукциона, он попадает во вкладку «Утвержденные», где указан кто победитель и с какой ставкой.
                  <img
                    src="/img/pages/customer-manual/16.jpg"
                    alt=""
                  >
                  <ol>
                    <li>
                      После утверждения Рейс становится доступным перевозчику для заполнения данных (переходит на вкладку «Рейсы»
                      <img
                        src="/img/pages/customer-manual/17.jpg"
                        alt=""
                      >
                    </li>
                    <li>Утвержденный Аукцион нельзя уже отменить или перевести в черновик.</li>
                    <li>
                      В ЦУП и Пегас приходят данные по перевозчику и выигранной ставки(причина АУКЦИОН), даже без заполнения данных перевозчиком, при этом удаление перевозчика и  строки индивидуального тарифа с причиной отклонение «Аукцион» = заблокировано, если есть необходимость  скорректировать  тариф, то делаем это путем отклонения(добавление еще строк в расшифровку).  Также заблокирована смена перевозчика.
                      <img
                        src="/img/pages/customer-manual/18.jpg"
                        alt=""
                      >
                      <img
                        src="/img/pages/customer-manual/19.jpg"
                        alt=""
                      >
                      <img
                        src="/img/pages/customer-manual/20.jpg"
                        alt=""
                      >
                    </li>
                  </ol>
                </li>
                <li><b>Отмена Аукциона.</b> До утверждения рейса Вы можете отменить аукцион в любой момент и аукцион будет находится во вкладке «Отмененные», чтобы заново запустить Аукцион по рейсу, для которого Аукцион отменили необходимо заново отправить рейс на аукцион.</li>
                <li>
                  <b>Просмотр истории по аукциону</b> – открыть любой аукцион, вкладка история.
                  <img
                    src="/img/pages/customer-manual/21.jpg"
                    alt=""
                  >
                </li>
                <li>
                  <b>Управление списками перевозчиков.</b> Списки перевозчиков необходимы для определения участников Аукциона. По умолчанию созданы группы «Перевозчики без НДС», «Перевозчики с НДС», «Все перевозчики» эти группы можно редактировать, либо создавать новые ( в том числе копирование любого из выбранного списка»
                  <img
                    src="/img/pages/customer-manual/22.jpg"
                    alt=""
                  >
                </li>
                <li>
                  <b>Управление уведомлениями</b> – управление всеми уведомлениями, в том числе по аукциону производится в Ваших настройках пользователя
                  <img
                    src="/img/pages/customer-manual/23.jpg"
                    alt=""
                  >
                  <ol>
                    <li>
                      Вкладка «Настройка источников оповещений» – здесь Вы можете настроить два параметра:
                      <ol>
                        <li>Получать уведомления только по рейсам(аукционам) для выбранных Вами групп менеджеров СТЛ (МСК Урал, МСК Сибирь и т.д.)</li>
                        <li>
                          Получать уведомления только по рейсам(аукционам) конкретного перевозчика.
                          <img
                            src="/img/pages/customer-manual/24.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                    <li>Вкладка «Настройка оповещений»  - здесь Вы можете настроить какие оповещения Вам нужны: «События Аукционов», «События Рейсов», «События доверенностей»</li>
                    <li>
                      Уведомления могут быть, как на самом ЭТП под Колокольчиком, так и на вашу почту, которая указана в Вашем профиле
                      <img
                        src="/img/pages/customer-manual/25.jpg"
                        alt=""
                      >
                      <img
                        src="/img/pages/customer-manual/26.jpg"
                        alt=""
                      >
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li ref="list-item-2">
          <b>Раздел «Тендеры»</b>
          <ol>
            <li><b>Вход на ЭТП</b> осуществляется по вашему логину и паролю на windows.</li>
            <li><b>Тендер</b> – это набор плановых рейсов по заранее установленному расписанию подачи на погрузку с указанными параметрами, по которому выставляется лот для проведения тендера.</li>
            <li><b>Условия проведения Тендера</b> – перевозчик должен иметь действующий договор перевозки и быть зарегистрированным пользователем на ЭТП</li>
            <li>
              <b>Как создать Тендер</b>
              <ol>
                <li>Через ЭТП</li>
              </ol>
            </li>
            <li>
              <b>Виды Тендера:</b>
              <ol>
                <li>Черновик – Тендер, ожидающий запуска.</li>
                <li>
                  Активный –  Тендер, который уже начался и не закончился на настоящее время
                  <ol>
                    <li>Отложенный = Тендер, который начнется в установленное время.</li>
                  </ol>
                </li>
                <li>
                  Завершенный – Тендер, который был завершен:
                  <ol>
                    <li>Выигран перевозчиком</li>
                    <li>Не выигран никем из участников и время Тендера закончилось</li>
                  </ol>
                </li>
                <li>Утвержденный – Тендер, который перевозчик забрал в обработку (занесение данных по водителю, ТС)</li>
                <li>Отмененный – Тендер, который был отменен сотрудником СТЛ (логистом).</li>
              </ol>
            </li>
            <li>
              <b>Краткая схема процесса:</b>
              <ol>
                <li>Сотрудник СТЛ(логист) создает новый Тендер на ЭТП</li>
                <li>Сотрудник СТЛ(логист) запускает Тендер на  ЭТП</li>
                <li>Перевозчик уведомляется о старте Тендера в ЭТП или на почту</li>
                <li>Перевозчик может участвовать в Тендере отправить свое предложение по стоимости, ориентируются на начальную цену, установленную сотрудником СТЛ (логистом). При этом изначальная ставка может быть либо с НДС либо без (указано в Тендере).</li>
                <li>После завершения Тендер, сотрудник СТЛ (логист) назначает победителя, руководствуюсь наименьшей ставкой за Тендер и способностью перевозчика выполнить условия Тендера (не всегда самое дешевое предложение под силу перевозчику с малым количествами транспорта), о чем будет уведомление в ЭТП либо на почту</li>
                <li>Если по Тендеру не было ни одного предложения, его можно перезапустить, если тендер еще актуален. </li>
                <li>После выигранного Тендера, перевозчик должен подтвердить выполнение рейсов (забрать в обработку). Система ЭТП Автоматически создать рейсы на весь период расписания рейсов по всему Тендеру. Такие рейсы придут с обменом в Пегас и ЦУП и их невозможно будет удалить.</li>
                <li>После подтверждения рейсов, рейсы переходит в ЭТП на вкладку «Рейсы» и перевозчик должен заполнить все данные по рейсу и отправить данные в информационную систему ПЭК (данные отобразятся в ЦУП и 1 С – водитель, ТС, выигранная ставка).</li>
                <li>Сотрудник СТЛ(логист) может менять маршрут рейса только в рамках филиала первой и последней точки маршрута, если между первой и последней точкой добавляются промежуточные точки заезда, то они дополнительно оплачиваются согласно установленным по договоре перевозки либо доп. соглашением между Вами и  ОО «ПЭК»</li>
              </ol>
            </li>
            <li>
              <b>Подробная инструкция:</b>
              <ol>
                <li>
                  <b>Создание Тендера</b> – тендер создается только на ЭТП в вашем Личном кабинете
                  <ol>
                    <li>
                      Для создания тендера, необходимо перейти на вкладку «Тендеры» и нажать кнопку «Добавить»
                      <img
                        src="/img/pages/customer-manual/27.jpg"
                        alt=""
                      >
                    </li>
                    <li>
                      Открывается форма создания тендера, где необходимо заполнить данные:
                      <ol>
                        <li>Название тендера</li>
                        <li>
                          Маршрут и расписание №1
                          <ol>
                            <li>Код маршрута – выбрать из списка</li>
                            <li>Стоимость одного рейса по маршруту</li>
                            <li>Габариты ТС</li>
                            <li>Погрузка/выгрузка(верхняя, задняя, боковая)</li>
                            <li>Тип кузова</li>
                            <li>Диапазон перевозок – Даты в которые будут осуществляться подача рейсов на погрузку</li>
                            <li>
                              Расписание – в выбранной диапазон перевозок необходимо добавить на необходимы день недели время подачи ТС, система сама посчитает сколько в указанный диапазон  и расписание попадает рейсов.
                              <img
                                src="/img/pages/customer-manual/28.jpg"
                                alt=""
                              >
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      В Тендер Можно добавить неограниченное кол-во маршрутов, нажав кнопку «Добавить маршрут» и все эти рейсы будут в одном тендере.
                      <img
                        src="/img/pages/customer-manual/29.jpg"
                        alt=""
                      >
                    </li>
                    <li>
                      Необходимо выбрать Группу перевозчиков (выбранные перевозчики буду видеть запущенный тендер)
                      <img
                        src="/img/pages/customer-manual/30.jpg"
                        alt=""
                      >
                      <ol>
                        <li>
                          Либо назначить перевозчиков вручную
                          <img
                            src="/img/pages/customer-manual/31.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                    <li>
                      Общую сумму по Тендеру система считает сама, Вам необходимо выбрать «С НДС» или «Без НДС»
                      <img
                        src="/img/pages/customer-manual/32.jpg"
                        alt=""
                      >
                    </li>
                    <li>
                      Далее у Вас есть выбор:
                      <ol>
                        <li>«Создать Тендер» - создание Тендера без запуска, Тендер переходит во вкладку «<b>Черновик</b>»</li>
                        <li>«Создать и запустить Тендер» - создание и запуск тендера, Тендер переходит во вкладку «<b>Активные</b>»</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Запуск Тендера</b>
                  <ol>
                    <li>
                      После Сохранения тендера он попадает на  «Черновики».
                      <ol>
                        <li>
                          В любой момент Вы можете совсем отменить тендер, кнопка «красный крест» - Тендер попадет во вкладку «Отмененные»
                          <img
                            src="/img/pages/customer-manual/33.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                    <li>
                      Для Запуска Тендера необходимо нажать кнопку «Запустить Тендер»
                      <img
                        src="/img/pages/customer-manual/34.jpg"
                        alt=""
                      >
                    </li>
                    <li>
                      Далее выбрать время запуска тендера:
                      <ol>
                        <li>
                          Сейчас
                          <img
                            src="/img/pages/customer-manual/35.jpg"
                            alt=""
                          >
                        </li>
                        <li>
                          Либо Отложенный
                          <img
                            src="/img/pages/customer-manual/36.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                    <li>
                      И нажать кнопку «Запустить Тендер», Тендер переходит на вкладку «Активные» и все выбранные Вами перевозчики в тендере видят Тендер у себя в Личном Кабинете на ЭТП
                      <img
                        src="/img/pages/customer-manual/37.jpg"
                        alt=""
                      >
                      <ol>
                        <li>
                          В любой момент Вы можете вернуть Тендер в черновик, кнопка «Вернуть в черновик» либо совсем отменить Тендер, кнопка «красный крест» - тендер попадет во вкладку «Отмененные»
                          <img
                            src="/img/pages/customer-manual/38.jpg"
                            alt=""
                          >
                        </li>
                      </ol>
                    </li>
                    <li>Далее происходит прием предложение по Тендеры, статус «Прием предложений»</li>
                    <li>
                      Количество поступивших предложений Вы можете увидеть в соответствующей графе:
                      <img
                        src="/img/pages/customer-manual/39.jpg"
                        alt=""
                      >
                    </li>
                    <li>
                      Вы можете досрочно (до окончания аукциона) назначить победителя- любого участника, сделавшего предложение, нажав кнопку «назначить победителем» и рейс перейдет во вкладку «Завершенные»
                      <img
                        src="/img/pages/customer-manual/40.jpg"
                        alt=""
                      >
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Завершение Тендера.</b> После окончания тендера, тендер попадает во вкладку «Завершенные», его результат может быть:
                  <ol>
                    <li>Статус <b>«Нет победителя»</b> - статус присваивается пока сотрудник СТЛ(логист) не назначит победителя по тендеру</li>
                    <li>
                      Если Вас не устраивает предложения от перевозчиков, либо предложений не было и Тендер все еще актуальный, Вы можете его перезапустить
                      <img
                        src="/img/pages/customer-manual/41.jpg"
                        alt=""
                      >
                    </li>
                    <li>
                      Статус <b>«Есть победитель»</b> - статус присваивается в случае, когда Вы назначите победителя из перевозчиков, сделавших предложения.
                      <ol>
                        <li>
                          Перевозчик может:
                          <ol>
                            <li>
                              <b>«Забрать рейсы в обработку»</b> - в таком случае все  рейсы  по тендеры будут созданы в ЭТП, придут с обменом в 1 С и ЦУП и появятся у перевозчика во вкладке рейсы и перевозчик сам сможет заполнить данные по водителю и ТС и все данные в том числе и фрахт придет с обменом в 1 С и ЦУП.
                              <ol>
                                <li>Если сумма предложения отличается от начальной, то фрахт распределяется пропорционально начальной стоимости по каждому маршруту. (например 2 рейса МБВР –начальная 30000 и 1 рейса  ВРМБ – 25000, 3 рейса по тендеру на сумму 85000, перевозчик заберет тендер за 80000, то МБВР(30000*2/85000)*800000/2=28400 И ВРМБ = МБВР(25000*1/85000)*800000/1=23200 Итого два рейса МБВР=56800 +  рейс ВРМБ 23200 =80000.)</li>
                                <li>
                                  Фрахт по рейсу в 1С и ЦУП установится автоматически (индивидуальный тариф с причиной «Победа по тендеру»)
                                  <img
                                    src="/img/pages/customer-manual/42.jpg"
                                    alt=""
                                  >
                                </li>
                              </ol>
                            </li>
                            <li>Даже, если перевозчик не заполнит данные по водителю и ТС, фрахт по рейсу придет в ЦУП и Пегас.</li>
                            <li><b><u>В выигранном Тендере  вы не сможете менять первую и последнюю точку маршрута только в рамках одного филиала</u></b></li>
                            <li><b><u>Вы сможете добавлять промежуточные точки и добавлять стоимость за их заезд только путем отклонения от выигранной ставки</u></b></li>
                            <li><b><u>Вы сможете добавлять промежуточные точки и добавлять стоимость за их заезд только путем отклонения от выигранной ставки</u></b></li>
                            <li><b><u>Вы не сможете удалить или пометить на удаление Заказ ТС и Рейс выигранный по тендеру ни в ЦУП ни в 1 С. Постановка рейсов по тендеры обязательна.</u></b></li>
                          </ol>
                        </li>
                        <li>
                          <b>«Отказаться от выигранных рейсов»</b> - при отказе перевозчика от выигранных рейса остается в вкладке «Завершенные» со статусом «Нет победителя»
                          <ol>
                            <li>После отказа перевозчика от рейсов вы можете: «Перезапустить Тендер», «Вернуть в черновик», «Отменить Тендер»</li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Утверждение Тендера.</b> После  подтверждения перевозчиком выигранного им Тендера, он попадает во вкладку «Утвержденные», где указан кто победитель и с какой ставкой.
                  <img
                    src="/img/pages/customer-manual/43.jpg"
                    alt=""
                  >
                  <ol>
                    <li>После утверждения Рейсов, они становится доступным перевозчику для заполнения данных (рейсы появляются  на вкладке «Рейсы» в ЭТП у перевозчика)</li>
                    <li>Утвержденный Тендер нельзя уже отменить или перевести в черновик.</li>
                  </ol>
                </li>
                <li><b>Отмена Тендера.</b> До утверждения рейсов Вы можете отменить тендер в любой момент и тендер будет находится во вкладке «Отмененные», чтобы заново запустить Тендер по рейсам, необходимо создать новый тендер</li>
                <li>
                  <b>Просмотр истории по Тендеру</b> – открыть любой тендер, вкладка история.
                  <img
                    src="/img/pages/customer-manual/44.jpg"
                    alt=""
                  >
                </li>
                <li>
                  <b>Управление списками перевозчиков.</b> Списки перевозчиков необходимы для определения участников Тендера. По умолчанию созданы группы «Перевозчики без НДС», «Перевозчики с НДС», «Все верифицированные перевозчики» эти группы можно редактировать, либо создавать новые ( в том числе копирование любого из выбранного списка»
                  <img
                    src="/img/pages/customer-manual/45.jpg"
                    alt=""
                  >
                </li>
                <li>
                  <b>Управление уведомлениями</b> – управление всеми уведомлениями, в том числе по Тендеры производится в Ваших настройках пользователя
                  <img
                    src="/img/pages/customer-manual/46.jpg"
                    alt=""
                  >
                  <img
                    src="/img/pages/customer-manual/47.jpg"
                    alt=""
                  >
                  <ol>
                    <li>Вкладка «Настройка источников оповещений» – здесь Вы можете настроить два параметра:</li>
                    <ol>
                      <li>
                        Получать уведомления только по рейсам(Тендерам) для выбранных Вами групп менеджеров СТЛ (МСК Урал, МСК Сибирь и т.д.)
                        <img
                          src="/img/pages/customer-manual/48.jpg"
                          alt=""
                        >
                      </li>
                      <li>
                        Получать уведомления только по доверенностям конкретного перевозчика.
                        <img
                          src="/img/pages/customer-manual/49.jpg"
                          alt=""
                        >
                      </li>
                    </ol>
                    <li>Вкладка «Настройка оповещений»  - здесь Вы можете настроить какие оповещения Вам нужны: «События Тендеров», «События Аукционов», «События Рейсов», «События доверенностей»</li>
                    <li>
                      Уведомления могут быть, как на самом ЭТП под Колокольчиком, так и на вашу почту, которая указана в Вашем профиле
                      <img
                        src="/img/pages/customer-manual/50.jpg"
                        alt=""
                      >
                      <img
                        src="/img/pages/customer-manual/51.jpg"
                        alt=""
                      >
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li ref="list-item-3">
          <b>Верификация водителей</b>
          <p>Создание новых водителей и связка уже имеющихся водители в базе данных ПЭК с перевозчиком происходит на площадке ЭТП.</p>
          <p><b><u>Ваша задача принять решение по поступившей заявки от перевозчика на проверку нового водителя</u></b></p>
          <p><b>Краткий процесс:</b></p>
          <ol>
            <li>Водитель ранее не проверялся в СБ компании ПЭК
              <ol>
                <li>Перевозчик создаёт в ЭТП нового водителя/li>
                <li>Сотрудник ПЭК сравнивает приложенные к карточке водителя  сканы с введенными в карточку данными и узнает о необходимости проверять данного водителя (заявка от перевозчика на водителя на конкретный рейс (<b>Внимание: производить отказ от проверки водителя, если водитель не заявлен в ПЭК, иначе перевозчик может нами воспользоваться, как бесплатным сервисом по проверке водителей</b>)) и принимает решение:
                  <ol>
                    <li>«Отправить на верфикацию в СБ» – отправить на проверку в СБ</li>
                    <li>«Вернуть перевозчику»</li>
                    <li>«Отклонить»</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>Водитель ранее проверялся в СБ компании ПЭК, такой водитель определяется автоматически системой при совпадении паспортных данных и ФИО (т.е. новая карточка водителя не будет создавать, будет производится сверка уже имеющейся карточки с теми данными что ввел перевозчик.</li>
          </ol>
          <p><b>Подробный процесс:</b></p>
          <ol>
            <li><b><u>Водитель ранее не проверялся в СБ компании ПЭК</u></b>
              <ol>
                <li>Как узнать о новой заявке на проверку водителя – меню «<b>Водители перевозчиков</b>», в фильтре устанавливаем допуск «<b>на проверке логистом</b>», за ходим и обновляем эту страницу каждые 30 мин, т.к. оповещений на почту пока нет.
                  <img
                    src="/img/pages/customer-manual/52.jpg"
                    alt=""
                  >
                </li>
                <li>При появлении новой заявки, открываем, значок «глаз» и:
                  <ol>
                    <li>Сравниваем паспортные данные введенные в карточке с теми, что приложены в сканах
                      <ol>
                        <li>Серия паспорта</li>
                        <li>Номер паспорта</li>
                        <li>Дата выдачи паспорта</li>
                        <li>Кем выдан паспорт</li>
                        <li>Фамилия</li>
                        <li>Имя</li>
                        <li>Отчество</li>
                        <li>Номер водительского удостоверения</li>
                        <li>Проверяем наличие скана на лицевую сторону паспорта</li>
                        <li>Проверяем наличие скана паспорта с пропиской</li>
                        <li>Проверяем наличие скана лицевой и обратной стороны водительского удостоверения</li>
                        <li>Проверяем наличие и корректность скана по договорным отношениям перевозчика и водителя</li>
                        <li>Проверяем корректность установленных данных по допуску (допуск авто, контейнер, грузопровод)</li>
                      </ol>
                    </li>
                    <li>1.2.2. После проверки принимаем решение:
                      <img
                        src="/img/pages/customer-manual/53.jpg"
                        alt=""
                      >
                      <ol>
                        <li><b>Если все данные заполнены корректно, то нажимаем кнопку «Отправить на верификацию в СБ»</b>, после нажатия будет:
                          <ol>
                            <li>Автоматически Создаваться карточка водителей (в Пегас и БД Водители) с отметкой СБ.</li>
                            <li>Автоматически формируется письмо на ящики <a href="mailto:dsb.voditeli@pecom.ru">dsb.voditeli@pecom.ru</a>;
                              <a href="mailto:doc_drivers@pecom.ru">doc_drivers@pecom.ru</a> с заявкой на проверку водителя и вложенными файлами сканов
                              <img
                                src="/img/pages/customer-manual/54.jpg"
                                alt=""
                              >
                            </li>
                            <li>По результатам проверки (решение и отметка СБ) автоматически отправляется в ЭТП.</li>
                          </ol>
                        </li>
                        <li><b>Если заполнены  не корректно,  но есть заявка на данного водителя, то нажимаем кнопку «Вернуть на редактирование» - Отписываем в чате Viber в группе СТЛ</b> о том, что  необходимо связаться с перевозчиком «…..», по водителю «…..», вернул на редактирование  т.к. такие то «…..» расхождения. Перевозчику необходимо скорректировать водителя.</li>
                        <li><b>Если нет заявки на проверку данного водителя, то нажимаем кнопку «Отказать», Отписываем в чате Viber в группе СТЛ</b> о том, что перевозчику «…..», по водителю «…..»,  отказано в проверке т.к. водитель не заявлен ни в один из рейсов.</li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li><b>Водитель ранее уже проверялся в СБ компании ПЭК</b>
              <ol>
                <li>Как узнать о новой заявке на проверку водителя – меню <b>«Черновики Водителей»</b>, в фильтре устанавливаем допуск <b>«Новые»</b>, за ходим и обновляем эту страницу каждые 30 мин, т.к. оповещений на почту пока нет. <u>Сюда попадают водители уже ранее проверявшиеся в компании ПЭК, но от иного перевозчика</u>.
                  <img
                    src="/img/pages/customer-manual/52.jpg"
                    alt=""
                  >
                </li>
                <li>При появлении новой заявки, открываем, значок «глаз» и сравниваем данные6 «данные введенные перевозчиком» - левая част и «данные существующего водителя» левая часть. Система сравнивает полное совпадение данных, далее принимаем решение:
                  <ol>
                    <li>Могут быть действительно введены корректные данные, но пробел не там или запятая не там, если есть понимание, что левая и правая часть одно и тоже. То проверяем приложенные сканы с введенными данными:</li>
                    <li>Сравниваем паспортные данные введенные в карточке с теми, что приложены в сканах
                      <ol>
                        <li>Серия паспорта</li>
                        <li>Номер паспорта</li>
                        <li>Дата выдачи паспорта</li>
                        <li>Кем выдан паспорт</li>
                        <li>Фамилия</li>
                        <li>Имя</li>
                        <li>Отчество</li>
                        <li>Номер водительского удостоверения</li>
                        <li>Проверяем наличие скана на лицевую сторону паспорта</li>
                        <li>Проверяем наличие скана паспорта с пропиской</li>
                        <li>Проверяем наличие скана лицевой и обратной стороны водительского удостоверения</li>
                        <li>Проверяем наличие и корректность скана по договорным отношениям перевозчика и водителя</li>
                        <li>Проверяем корректность установленных данных по допуску (допуск авто, контейнер, грузопровод)</li>
                      </ol>
                    </li>
                    <li>Если все корректно, то нажимаем кнопу <b>«Принять»</b>, существующая карточка водителя связывается с перевозчиком. <b>Оповещаем СБ о смене перевозчика письменно приложив сканы (текущий процесс)</b>. В будущем будет автоматически формироваться письмо
                      <ol>
                        <li>Если есть расхождения, то нажимаем кнопку <b>«Отклонить», Отписываем в чате Viber в группе СТЛ</b> о том, что необходимо связаться с перевозчиком «…..», по водителю «…..», отказано водителю в проверке  т.к. такие то «…..» расхождения. Перевозчику необходимо создать заново водителя с корректными данными.</li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserManual',
  methods: {
    scrollToItem(itemRef) {
      this.$refs[itemRef].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    },
  },
};
</script>

<style lang="scss">
  .content-page {

    img {
      display: block;
      max-width: 100%;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    ol {
      list-style: none;
      counter-reset: li;
      padding-left: 0;
      @media screen and (max-width: 767px) {
        padding-left: 0;
      }

      li {
        padding-top: 55px;
      }

      ol {
        padding-left: 20px;
        @media screen and (max-width: 767px) {
          padding-left: 0;
        }

        li {
          padding-top: 0;
        }
      }
    }
    li:before {
      counter-increment: li;
      content: counters(li,".") ". ";
    }

    &__navigation {
      padding: .75rem 1.25rem;
      background-color: #f0f3f5;
      border: 1px solid #c8ced3;
      margin-bottom: 0;

      &-list {
        margin-bottom: 0;
      }
      &-item {
        padding-top: 0 !important;
      }
      &-link {
        color: #20a8d8;

        &:hover {
          color: #167495;
          text-decoration: underline;
        }
      }
    }
  }
</style>
